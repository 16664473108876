import { useLocalStorageValue } from '@react-hookz/web';

type useDomainProps = {
  domain: string;
  setDomain: (domain: string) => void;
};

export const useDefaultDomain = (): useDomainProps => {
  const [domain, setDomain] = useLocalStorageValue<string>('grace-domain', '');

  return {
    domain,
    setDomain,
  };
};
