import { Logo } from '@corellium/metal';
import Link from 'next/link';
import type { FC } from 'react';

const AppLogo: FC = () => (
  <Link href="/" className="flex text-primary">
    <Logo type="symbol" />
  </Link>
);

export default AppLogo;
