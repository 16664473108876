import { Avatar, Container, Dropdown, Button } from '@corellium/metal';
import type { FC } from 'react';
import AppLogo from 'components/AppLogo';
import useUser from 'hooks/useUser';
import { ArrowUpRightIcon, LogOutIcon, MenuIcon } from 'lucide-react';
import { ThemeToggle } from 'components/ThemeToggle';

export const Header: FC = () => {
  const { user, logout } = useUser();

  const userMenu = [
    {
      items: [
        {
          element: <ThemeToggle />,
        },
      ],
    },
    {
      items: [
        {
          icon: <LogOutIcon />,
          label: 'Logout',
          onClick: logout,
        },
      ],
    },
  ];

  return (
    <div className="flex h-[4.5rem] items-center border-b border-border bg-background">
      <Container size="wide">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <AppLogo />
            <div className="hidden items-center gap-4 sm:flex">
              <Button
                variant="link"
                size="md"
                iconRight={
                  <ArrowUpRightIcon className="text-muted-foreground" />
                }
                asChild
              >
                <a
                  href="https://support.corellium.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Support Center
                </a>
              </Button>
              <Button
                variant="link"
                size="md"
                iconRight={
                  <ArrowUpRightIcon className="text-muted-foreground" />
                }
                asChild
              >
                <a
                  href="https://support.corellium.com/releases"
                  target="_blank"
                  rel="noreferrer"
                >
                  Release Notes
                </a>
              </Button>
              <Button
                variant="link"
                size="md"
                iconRight={
                  <ArrowUpRightIcon className="text-muted-foreground" />
                }
                asChild
              >
                <a
                  href="https://support.corellium.com/getting-started/onsite-setup"
                  target="_blank"
                  rel="noreferrer"
                >
                  Setup Guide
                </a>
              </Button>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <Dropdown items={userMenu}>
              <Button variant="link" className="!rounded-full !no-underline">
                <Avatar name={user?.user.label} />
              </Button>
            </Dropdown>
            <div className="flex sm:hidden">
              <Dropdown
                items={[
                  {
                    items: [
                      {
                        icon: (
                          <ArrowUpRightIcon className="text-muted-foreground" />
                        ),
                        label: 'Support Center',
                        href: 'https://support.corellium.com/',
                      },
                      {
                        icon: (
                          <ArrowUpRightIcon className="text-muted-foreground" />
                        ),
                        label: 'Release Notes',
                        href: 'https://support.corellium.com/releases',
                      },
                      {
                        icon: (
                          <ArrowUpRightIcon className="text-muted-foreground" />
                        ),
                        label: 'Setup Guide',
                        href: 'https://support.corellium.com/getting-started/onsite-setup',
                      },
                    ],
                  },
                ]}
              >
                <Button
                  variant="link"
                  iconLeft={<MenuIcon />}
                  className="!no-underline"
                />
              </Dropdown>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
