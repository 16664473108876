import { LoadingIndicator } from '@corellium/metal';
import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { Header } from 'components/Header';
import { useIntercom } from 'react-use-intercom';
import useUser from 'hooks/useUser';

type LayoutProps = PropsWithChildren<{
  isFullscreen?: boolean;
  isPublicRoute?: boolean;
  isAuthenticated?: boolean;
}>;

export const Layout: FC<LayoutProps> = ({
  isFullscreen,
  isPublicRoute,
  isAuthenticated,
  children,
}) => {
  const { user } = useUser();
  const { boot } = useIntercom();

  useEffect(() => {
    if (user?.user.label) {
      boot({
        userId: user.user.id,
        name: user.user.label,
        customAttributes: {
          domain: user.domain.name,
          onsite: user.domain.filesOnly,
        },
      });
    }
  }, [
    boot,
    user?.domain.filesOnly,
    user?.domain.name,
    user?.user.id,
    user?.user.label,
    user?.user.name,
  ]);

  if (!isAuthenticated && !isPublicRoute) {
    return (
      <div className="flex h-screen flex-1 items-center justify-center">
        <LoadingIndicator label="Loading" size="md" />
      </div>
    );
  }

  return (
    <>
      {!isFullscreen && <Header />}
      {children}
      {!isFullscreen && <div className="pb-10" />}
    </>
  );
};
