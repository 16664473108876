import { UseQueryOptions } from '@tanstack/react-query';

export type QueryHookOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData>,
  'queryFn' | 'queryKey' | 'context'
>;

export enum QUERY {
  FILES = 'files',
}

export enum ERROR_TITLES {
  DEFAULT = 'An error occurred',
}

export enum ERROR_DESCRIPTIONS {
  DEFAULT = 'An unexpected error occurred. Please try again later.',
}
