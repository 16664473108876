import { useRouter } from 'next/router';
import { useLocalStorageValue } from '@react-hookz/web';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/nextjs';
import { captureException } from '@sentry/nextjs';
import type { UserProps } from 'types/user';
import { getTokenExpiration } from 'utils/token';
import { getErrorDescription, handleError } from 'utils/handleError';
import { isTimeSynced } from 'utils/isTimeSynced';
import { jsonRequest, API } from 'utils/http';
import { useDefaultDomain } from './useDefaultDomain';

type useUserReturnProps = {
  user?: UserProps | null;
  login: (username: string, password: string, domain: string) => Promise<void>;
  logout: () => void;
  reauthenticate: () => Promise<void>;
  queryAuth: (queryToken: string, queryDomain: string) => Promise<void>;
};

const useUser = (): useUserReturnProps => {
  const client = useQueryClient();
  const router = useRouter();
  const { domain, setDomain } = useDefaultDomain();
  const [user, setUser, removeUser] = useLocalStorageValue<UserProps | null>(
    'grace-user',
    null
  );

  const logout = async () => {
    removeUser();
    setDomain('');
    client.clear();
    Sentry.setUser(null);
    await router.push('/login');
  };

  const login: useUserReturnProps['login'] = async (
    username,
    password,
    loginDomain
  ) => {
    try {
      const { endpoint, headers } = jsonRequest('/api/v1/tokens', loginDomain);
      const body = { ...(username && password && { username, password }) };
      const { data } = await API.post<UserProps>(endpoint, body, {
        headers,
      });

      if (data.time) {
        if (!isTimeSynced(data.time)) {
          const serverDate = new Date(data.time).toString();
          const clientDate = new Date().toString();
          const msg = `The time is set incorrectly on your machine.
          We believe the date is ${serverDate} while your computer believes the date is ${clientDate}.
          Your machine needs to be set to the correct time.`;
          throw new Error(msg);
        }
      }

      if (data.errorID === 'InvalidTOTP') {
        throw new Error('The one-time code you supplied is incorrect.');
      }

      if (!data.domain.filesOnly) {
        throw new Error(
          'You are not allowed to access files app for this domain.'
        );
      }

      setUser(data);
      setDomain(loginDomain);
      Sentry.setUser({ ...data, loginDomain });
    } catch (error) {
      const description = getErrorDescription(error);

      setUser(null);
      setDomain('');
      Sentry.setUser(null);
      captureException(error, {
        tags: {
          auth: 'LOGIN',
        },
      });
      throw new Error(description);
    }
  };

  const reauthenticate = async () => {
    if (!user?.token) {
      const err = new Error('Invalid or missing authorization token');

      captureException(err, {
        tags: {
          auth: 'REAUTHENTICATE',
        },
      });

      throw err;
    }

    const tokenExpiration = getTokenExpiration(user.token);
    const timeUntilExpiration =
      tokenExpiration.getTime() - new Date().getTime();

    if (timeUntilExpiration <= 0 || tokenExpiration <= new Date()) {
      logout().catch(handleError);
      return;
    }

    if (timeUntilExpiration >= 5 * 1000 * 60 * 60) {
      return;
    }

    const { endpoint, headers } = jsonRequest('/api/v1/tokens', domain);
    await API.post<useUserReturnProps['user']>(
      endpoint,
      { token: user.token },
      {
        headers,
      }
    );
  };

  const queryAuth = async (queryToken: string, queryDomain: string) => {
    if (!queryToken) {
      const err = new Error('Invalid or missing authorization token');

      captureException(err, {
        tags: {
          auth: 'QUERY_AUTH',
        },
      });

      throw err;
    }

    const { endpoint, headers } = jsonRequest('/api/v1/tokens', queryDomain);
    const { data } = await API.post<UserProps>(
      endpoint,
      { token: queryToken },
      {
        headers,
      }
    );

    setUser(data);
    Sentry.setUser({ ...data, loginDomain: queryDomain });
    setDomain(queryDomain);
  };

  return {
    user,
    login,
    logout,
    reauthenticate,
    queryAuth,
  };
};

export default useUser;
