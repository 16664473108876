import { Toggle } from '@corellium/metal';
import { useTheme } from 'next-themes';
import { FC } from 'react';

export const ThemeToggle: FC = () => {
  const { theme, setTheme } = useTheme();

  return (
    <div className="p-2">
      <Toggle
        size="sm"
        label="Dark mode"
        checked={theme === 'dark'}
        onCheckedChange={(checked) => setTheme(checked ? 'dark' : 'light')}
      />
    </div>
  );
};
